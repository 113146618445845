<template>
  <div class="video-page">
    <div class="video-banner"></div>
    <div class="container">
      <div class="content-wrap">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/home">首页</a></li>
            <li class="breadcrumb-item active" aria-current="page">
              安全警示短视频学习
            </li>
          </ol>
          <SearchBar @toSearch="toSearch"></SearchBar>
        </nav>
        <div class="feature">
          <div
            :class="['pk-box', curType == 1 ? 'active' : '']"
            @click="changeType(1)"
          >
            <img src="../../assets/safety/icon-ahy.png" />
            <span>按行业领域</span>
          </div>
          <div
            :class="['pk-box', curType == 2 ? 'active' : '']"
            @click="changeType(2)"
          >
            <img src="../../assets/safety//icon-agw.png" />
            <span>按特种作业</span>
          </div>
          <!-- <div class="pk-box">
            <img src="../../assets/safety/icon-video-rank.png" />
            <span>学习排行榜</span>
          </div> -->
        </div>
        <!-- 视频列表 -->
        <div>
          <ul class="nav nav-pills nav-orange" id="myTab" role="tablist">
            <li
              class="nav-item"
              role="presentation"
              v-for="item in categoryList"
              :key="item.category_id"
              @click="changeCategory(item)"
            >
              <button
                :class="[
                  'nav-link',
                  curCategory.category_id == item.category_id ? 'active' : '',
                ]"
                id="home-tab"
                data-bs-toggle="tab"
                data-bs-target="#home-tab-pane"
                type="button"
                role="tab"
                aria-controls="home-tab-pane"
                aria-selected="true"
              >
                {{ item.category_name }}
              </button>
            </li>
          </ul>
          <div class="video-list">
            <div
              class="card"
              v-for="item in data.list"
              :key="item.video_id"
              @click="toDetail(item)"
            >
              <div class="card-img-top">
                <img :src="item.cover" />
              </div>
              <div class="card-body">
                <div class="card-title">{{ item.title }}</div>
              </div>
            </div>
          </div>
          <Pagination
            @change="pageChanged"
            :current="1"
            :pageSize="10"
            :hideOnSinglePage="true"
            :total="parseInt(data.total)"
            :showQuickJumper="false"
            :showTotal="false"
            placement="center"
          />
          <!-- <PageNav @pageChanged="pageChanged"
                   :max="calcMaxPage(data.total)"
                   ref="page" /> -->
        </div>
        <!-- 排行榜 -->
        <!-- <ul class="nav nav-pills nav-orange" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
            <button
              class="nav-link active"
              id="home-tab"
              data-bs-toggle="tab"
              data-bs-target="#home-tab-pane"
              type="button"
              role="tab"
              aria-controls="home-tab-pane"
              aria-selected="true"
            >
              个人排行榜
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="profile-tab"
              data-bs-toggle="tab"
              data-bs-target="#profile-tab-pane"
              type="button"
              role="tab"
              aria-controls="profile-tab-pane"
              aria-selected="false"
            >
              区域数据
            </button>
          </li>
        </ul>
        <div class="filter-box">
          <select class="form-select" aria-label="Default select example">
            <option selected>全部</option>
            <option value="1">按行业领域</option>
            <option value="2">按特种作业</option>
          </select>
        </div>
        <div class="list" >
          <div class="list-cell">
            <div class="cell-left">
              <div class="name">adfasdfasd</div>
            </div>
            <div class="cell-right">
              <span class="ranking-number">ddddd</span>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import SearchBar from "@/components/SearchBar.vue";
import Pagination from "@/components/Pagination.vue";
import { videoCategoryList, videoList } from "@/network/API.js";
export default {
  components: {
    SearchBar,
    Pagination,
  },
  data() {
    return {
      curType: 1,
      curCategory: {},
      categoryList: [],
      searchKey: "",
      data: {},
      pageNumber: 1,
    };
  },
  created() {
    this.getCategoryList(1);
  },
  methods: {
    changeType(type) {
      if (type == this.curType) return;
      this.curType = type;
      this.getCategoryList(type);
    },
    changeCategory(cate) {
      if (cate.category_id == this.curCategory.category_id) return;
      this.curCategory = cate;
      this.getDataList();
    },
    async getCategoryList(type) {
      let data = await videoCategoryList({
        category_id: this.curType,
      });
      if (data.length > 0) {
        this.curCategory = data[0];
        this.categoryList = data;
        this.getDataList();
      }
    },
    toSearch(val) {
      this.searchKey = val;
      this.pageNumber = 1;
      this.getDataList();
    },
    async getDataList() {
      let data = await videoList({
        category_id: this.curCategory.category_id,
        page_size: 10,
        page: this.pageNumber,
        keyword: this.searchKey,
      });
      this.data = data;
    },
    pageChanged(val) {
      this.pageNumber = val.page;
      this.getDataList();
    },
    toDetail(item) {
      this.$router.push({
        name: "VideoDetail",
        params: {
          id: item.video_id,
          t: Date.now(),
        },
      });
    },
  },
};
</script>
<style>
.video-banner {
  width: 100%;
  height: 300px;
  background: url(../../assets/safety/banner-video.jpg) center center no-repeat;
  margin-bottom: -32px;
}
.feature {
  display: flex;
  justify-content: space-around;
}
.pk-box {
  width: 50%;
  padding: 20px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  border-bottom: 4px solid #fff;
  border-radius: 20px 20px 0 0;
  cursor: pointer;
  background: linear-gradient(
    0deg,
    #ffffff 0%,
    #feeeda 0%,
    #fef6ec 0%,
    #ffffff 100%
  );
}
.pk-box:hover span {
  color: #ff6d00;
}
.pk-box.active {
  border-bottom: 4px solid #ff6d00;
}
.pk-box.active span {
  color: #ff6d00;
}
.pk-box img {
  width: 60px;
  margin-right: 10px;
}

.pk-box span {
  font-size: 24px;
  font-weight: 500;
  color: #666666;
  line-height: 36px;
}

/* 选项卡 */

.nav-pills.nav-orange .nav-link {
  margin: 0;
  margin-right: 20px;
  padding-left: 30px;
  padding-right: 30px;
  color: #333;
}

.nav-pills.nav-orange .nav-link:hover {
  color: #ff6d00;
}

.nav-pills.nav-orange .nav-link.active {
  background: linear-gradient(107deg, #ffd993 0%, #ff6d00 99%);
  color: #fff;
  border: none;
}

.video-list {
  display: flex;
  flex-wrap: wrap;
  margin: 10px -15px 20px -15px;
}
.video-list .card {
  margin: 15px;
  width: calc((100% - 90px) / 3);
  border: none;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  border-radius: 10px;
  transition: all linear 0.2s;
}
.video-list .card:hover {
  transform: translateY(-5px);
}
nav {
  position: relative;
}
.search-box {
  top: 10px;
}
</style>
